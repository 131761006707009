import { Button, Checkbox, message, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Spacer from "../../Spacer";
import { GenerateScenarioButton } from "./GenerateScenarioButton";
import { ShipmentApiActionButton } from "./ShipmentApiActionButton";
import { TabProps } from "./TabProps";
import { ViewShipmentReportButton } from "./ViewShipmentReportButton";

function RecalculateShipmentStats(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  return (
    <ShipmentApiActionButton
      name="Recalculate Shipment Stats"
      onClick={async function (shipmentApi) {
        await shipmentApi.markInTransit({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={false}
    />
  );
}

interface RegenerateBillOfLadingButtonProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

function RegenerateBillOfLadingButton(
  props: RegenerateBillOfLadingButtonProps,
) {
  const { shipmentId } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendNotificationEmails, setSendNotifications] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.regenerateBillOfLadingForShipment({
        shipmentId,
        sendNotificationEmails,
      });
      await props.onRefresh();
      setIsModalVisible(false);
      message.success("BOL/Pickup Pack regenerated");
    } catch {
      message.error("Ooops something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>Regenerate Bill of Lading</p>

        <Checkbox
          checked={sendNotificationEmails}
          onChange={function (e) {
            setSendNotifications(e.target.checked);
          }}
        >
          Send notification email to booker and shipper?
        </Checkbox>
      </Modal>
      <Button onClick={showModal}>Regenerate BOL/Pickup Pack</Button>
    </>
  );
}

export function TechnicalTab(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const carrierRequestAuditsUrl = `/carrier-request-audits?shipmentId=${shipmentId}`;
  return (
    <>
      <ButtonRow>
        <GenerateScenarioButton {...props} />
        <Spacer width={8} />
        <Button target="_blank" href={carrierRequestAuditsUrl}>
          Carrier Request Audits
        </Button>
        <Spacer width={8} />
        <RegenerateBillOfLadingButton
          shipmentId={shipmentId}
          onRefresh={props.onRefresh}
        />
        <RecalculateShipmentStats {...props} />
        <Spacer width={8} />
        <ViewShipmentReportButton {...props} />
        <Spacer width={8} />
        <div>
          Longest {props.shipmentData.durationInfo.longestLabel}:{" "}
          {props.shipmentData.durationInfo.longestMs}ms
        </div>
        <div>Duration: {props.shipmentData.durationInfo.durationMs}ms</div>
      </ButtonRow>
    </>
  );
}
