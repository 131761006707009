import { ShipmentState } from "@freightsimple/generated-apollo-openapi-client";
import {
  ArrowRightOutlined,
  DownOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  MenuItemProps,
  message,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ReactNode } from "react";
import { NumericFormat } from "react-number-format";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import Colors from "../../Components/Colors";
import { CopyMenuItem } from "../../Components/CopyMenuItem";
import { DownloadJsonMenuItem } from "../../Components/DownloadJsonMenuItem";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import { Page } from "../../Components/Page";
import { Percentage } from "../../Components/Percentage";
import { ShipmentProblemTag } from "../../Components/ShipmentProblemTag";
import Stack from "../../Components/Stack";
import { UrlMenuItem } from "../../Components/UrlMenuItem";
import { ViewCompanyButton } from "../../Components/ViewCompanyButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { assertNever } from "../../Helpers/assertNever";
import { calculateMargin } from "../../Helpers/calculateMargin";
import { dashboardBaseUrl } from "../../Helpers/dashboardBaseUrl";
import { describeState } from "../../Helpers/describeState";
import { emojiForShipmentState } from "../../Helpers/emojiForShipmentState";
import { formatDate } from "../../Helpers/formatDate";
import { isBlank } from "@freightsimple/shared";
import { isPhone } from "../../Helpers/isPhone";
import { getMapUrlForShipment } from "../../Helpers/mapUtils";
import { isShipmentStarred } from "../../Helpers/starredShipments";
import { useAttach } from "../../Hooks/useAttach";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";
import { SnoozeInvoiceAuditMenuItem } from "../SnoozeInvoiceAuditMenuItem";
import { StarShipmentMenuItem } from "../StarShipmentMenu";
import { CancelInvoiceAuditSnoozeMenuItem } from "./CancelInvoiceAuditSnoozeMenuItem";
import { ClaudeButton } from "./ClaudeButton";
import { ExpectedDeliveryDateOverlay } from "./ExpectedDeliveryDateOverlay";
import { FedExAddressValidationMenuItem } from "./FedExAddressValidationMenuItem";
import { GenerateXeroClaimInvoicesMenuItem } from "./GeneralXeroClaimInvoicesMenuItem";
import { MoveShipmentToDifferentCompanyMenuItem } from "./MoveShipmentToDifferentCompanyMenuItem";
import { SetCarrierAuditFrontConversationIdMenuItem } from "./SetCarrierAuditFrontConversationIdMenuItem";
import { SetCarrierAuditTurnMenuItem } from "./SetCarrierAuditTurnMenuItem";
import { SetCustomerAuditFrontConversationIdMenuItem } from "./SetCustomerAuditFrontConversationIdMenuItem";
import { SetCustomerAuditTurnMenuItem } from "./SetCustomerAuditTurnMenuItem";
import { TabProps } from "./TabProps";
import { ViewCarrierPickupNumberHistoryMenuItem } from "./ViewCarrierPickupNumberHistoryMenuItem";
import { ViewEmailConversationsButton } from "./ViewEmailConversationsButton";
import { ViewPageLoadStatsMenuItem } from "./ViewPageLoadStatsMenuItem";
import { ViewProNumberHistoryMenuItem } from "./ViewProNumberHistoryMenuItem";
import { PauseCustomsDocsRemindersMenuItem } from "./PauseCustomsDocsRemindersOption";
import dayjs from "dayjs";
import { isInDeliveryWindow } from "./isInDeliveryWindow";
import { ViewShipmentDataMenuItem } from "./ViewShipmentDataMenuItem";

interface ShipmentStatProps {
  title: ReactNode;
  value: ReactNode | undefined;
  copyToClipboard?: boolean;
  renderEmptyAsUnknown?: boolean;
}

function ShipmentStatContent(props: ShipmentStatProps) {
  if (props.value === undefined || props.value === null || props.value === "") {
    if (props.renderEmptyAsUnknown) {
      return <div style={{ color: Colors.Red }}>Unknown</div>;
    } else {
      return <>-</>;
    }
  }

  if (props.copyToClipboard) {
    return <WithClipboardLink>{props.value.toString()}</WithClipboardLink>;
  }

  return <>{props.value}</>;
}
function ShipmentStat(props: ShipmentStatProps) {
  return (
    <Stack align="left">
      <div style={{ color: Colors.LightText, fontSize: "12px" }}>
        {props.title}
      </div>
      <div>
        <ShipmentStatContent {...props} />
      </div>
    </Stack>
  );
}

function ToggleDeletedMenuItem(props: TabProps) {
  const createShipmentApi = useShipmentsApi();

  if (props.shipmentData.shipment.shipment.state !== ShipmentState.Quoted) {
    return <></>;
  }

  const title = props.shipmentData.shipment.shipment.deleted
    ? "Undelete Shipment"
    : "Delete Shipment";

  const shouldDelete = props.shipmentData.shipment.shipment.deleted !== true;

  async function toggleDeleted() {
    const shipmentApi = await createShipmentApi();
    const shipmentId = props.shipmentData.shipmentId;
    try {
      await shipmentApi.toggleDeleted({
        shipmentId,
        deleted: shouldDelete,
      });
      await props.onRefresh();

      message.success(
        props.shipmentData.shipment.shipment.deleted ? "Undeleted" : "Deleted",
      );
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return <Menu.Item onClick={toggleDeleted}>{title}</Menu.Item>;
}

function ToggleInvoiceAuditOpenMenuItem(props: TabProps & MenuItemProps) {
  const createShipmentApi = useShipmentsApi();

  if (props.shipmentData.shipment.shipment.bookedAt === undefined) {
    return <></>;
  }

  const shouldOpen =
    props.shipmentData.shipment.shipment.invoiceAuditOpen !== true;

  const title = !shouldOpen
    ? "Set Invoice Audit Closed"
    : "Set Invoice Audit Open";

  async function toggleInvoiceAuditOpen() {
    const shipmentApi = await createShipmentApi();
    const shipmentId = props.shipmentData.shipmentId;
    try {
      await shipmentApi.toggleInvoiceAuditOpen({
        shipmentId,
        invoiceAuditOpen: shouldOpen,
      });
      await props.onRefresh();

      message.success(shouldOpen ? "Opened" : "Closed");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={toggleInvoiceAuditOpen} {...props}>
      <Tag color="red">Advanced</Tag> {title}
    </Menu.Item>
  );
}

function MarkQuotedMenuItem(props: TabProps & MenuItemProps) {
  const createShipmentApi = useShipmentsApi();

  async function markQuoted() {
    const shipmentApi = await createShipmentApi();
    const shipmentId = props.shipmentData.shipmentId;
    try {
      await shipmentApi.markQuoted({
        shipmentId,
      });
      await props.onRefresh();
      message.success("Marked Quoting Complete");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={markQuoted} {...props}>
      Mark Quoting Complete
    </Menu.Item>
  );
}

interface MondayShipmentMenuItemProps extends MenuItemProps {
  label: string;
  mondayId: string | undefined;
}

function MondayShipmentMenuItem(props: MondayShipmentMenuItemProps) {
  return (
    <UrlMenuItem
      {...props}
      title={`Monday ${props.label} Task`}
      url={`https://freightsimple.monday.com/boards/1183357790/views/41589222/pulses/${props.mondayId}`}
      disabled={isBlank(props.mondayId)}
    />
  );
}

function MondayCarrierInvoicesMenuItem(props: MondayShipmentMenuItemProps) {
  return (
    <UrlMenuItem
      {...props}
      title={`Monday ${props.label} Task`}
      url={`https://freightsimple.monday.com/boards/1259362202/views/41386415/pulses/${props.mondayId}`}
      disabled={isBlank(props.mondayId)}
    />
  );
}

function ExtrasMenu(props: TabProps) {
  const shipment = props.shipmentData.shipment.shipment;
  const shipmentId = shipment.shipmentId!;
  const state = shipment.state!;
  const copyShipmentId = useCopyToClipboard(shipmentId);
  const trackingId = shipment.trackingId;

  function isPrebooking() {
    switch (state) {
      case ShipmentState.QuoteRequested:
        return true;
      case ShipmentState.Quoted:
        return true;
      case ShipmentState.BookingFailed:
        return false;
      case ShipmentState.BookingConfirmed:
        return false;
      case ShipmentState.BookingRequested:
        return false;
      case ShipmentState.Delivered:
        return false;
      case ShipmentState.Cancelled:
        return false;
      case ShipmentState.InTransit:
        return false;
      case ShipmentState.Lost:
        return false;
      case ShipmentState.OnHold:
        return false;
      default:
        assertNever(state);
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={copyShipmentId} icon={<PaperClipOutlined />}>
        Copy Shipment Id to clipboard
      </Menu.Item>
      <Menu.Divider />
      <StarShipmentMenuItem {...props} onRefresh={props.onRefresh} />
      <PauseCustomsDocsRemindersMenuItem {...props} />
      <Menu.Divider />
      <CopyMenuItem
        title="Copy Dashboard Quotes Link"
        value={`${dashboardBaseUrl()}/book/quotes?shipmentId=${shipmentId}`}
        hide={!isPrebooking()}
      />
      <CopyMenuItem
        title="Copy Dashboard Link"
        value={`${dashboardBaseUrl()}/view-shipment?shipmentId=${shipmentId}`}
        hide={isPrebooking()}
      />
      <CopyMenuItem
        title="Copy Tracking Link"
        value={`${dashboardBaseUrl()}/track-shipment?trackingId=${trackingId}`}
        hide={isPrebooking()}
      />
      <Menu.Divider />
      <UrlMenuItem
        title="View in LogDNA"
        url={`https://app.logdna.com/d5a434225b/logs/view?q=metadata.shipmentId%3A${shipmentId}&hosts=freightsimple-production`}
      />
      <Menu.Divider />
      <Menu.SubMenu title="Monday Tasks">
        <MondayShipmentMenuItem
          label="Book Pickup"
          mondayId={
            props.shipmentData.shipment.shipment.handleBookPickupMondayId
          }
        />
        <MondayShipmentMenuItem
          label="Missed Pickup"
          mondayId={
            props.shipmentData.shipment.shipment.handleMissedPickupMondayId
          }
        />
        <MondayShipmentMenuItem
          label="Missed Delivery"
          mondayId={
            props.shipmentData.shipment.shipment.handleMissedDeliveryMondayId
          }
        />
        <MondayShipmentMenuItem
          label="Request Tracking Update"
          mondayId={
            props.shipmentData.shipment.shipment.requestTrackingUpdateMondayId
          }
        />
        <MondayShipmentMenuItem
          label="Request Delivery Date Update"
          mondayId={
            props.shipmentData.shipment.shipment
              .requestDeliveryDateUpdateMondayId
          }
        />
        <MondayCarrierInvoicesMenuItem
          label="Missing Invoice"
          mondayId={props.shipmentData.shipment.shipment.missingInvoiceMondayId}
        />
        <MondayCarrierInvoicesMenuItem
          label="Invoice Approval"
          mondayId={
            props.shipmentData.shipment.shipment.invoiceNeedsApprovalMondayId
          }
        />
      </Menu.SubMenu>
      <Menu.Divider />
      <MoveShipmentToDifferentCompanyMenuItem {...props} />

      <Menu.Divider />
      <FedExAddressValidationMenuItem {...props} />

      <Menu.Divider />
      <GenerateXeroClaimInvoicesMenuItem {...props} />
      <Menu.Divider />
      <ViewProNumberHistoryMenuItem
        shipmentId={props.shipmentData.shipmentId}
        onRefresh={props.onRefresh}
      />

      <ViewCarrierPickupNumberHistoryMenuItem
        shipmentId={props.shipmentData.shipmentId}
        onRefresh={props.onRefresh}
      />
      <Menu.Divider />
      <ViewPageLoadStatsMenuItem {...props} />
      <ViewShipmentDataMenuItem {...props} />

      <>
        <Menu.Divider />
        <ToggleDeletedMenuItem {...props} />
      </>

      <Menu.SubMenu title="Invoice Audits">
        {shipment.bookedAt !== undefined && (
          <>
            <ToggleInvoiceAuditOpenMenuItem {...props} />
            <SetCustomerAuditTurnMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              invoiceAuditCustomerTurn={
                props.shipmentData.shipment.shipment.invoiceAuditCustomerTurn
              }
              invoiceAuditCustomerTurnTime={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCustomerTurnTime
              }
            />
            <SetCarrierAuditTurnMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              invoiceAuditCarrierTurn={
                props.shipmentData.shipment.shipment.invoiceAuditCarrierTurn
              }
              invoiceAuditCarrierTurnTime={
                props.shipmentData.shipment.shipment.invoiceAuditCarrierTurnTime
              }
            />
            <SnoozeInvoiceAuditMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              currentSnoozeDate={
                props.shipmentData.shipment.shipment
                  .invoiceAuditSnoozedUntilDate
              }
            />
            <CancelInvoiceAuditSnoozeMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              currentSnoozeDate={
                props.shipmentData.shipment.shipment
                  .invoiceAuditSnoozedUntilDate
              }
            />
            <SetCustomerAuditFrontConversationIdMenuItem
              shipmentId={props.shipmentData.shipmentId!}
              onRefresh={props.onRefresh}
              invoiceAuditCustomerFrontConversationId={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCustomerFrontConversationId
              }
            />
            <SetCarrierAuditFrontConversationIdMenuItem
              shipmentId={props.shipmentData.shipmentId!}
              onRefresh={props.onRefresh}
              invoiceAuditCarrierFrontConversationId={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCarrierFrontConversationId
              }
            />
          </>
        )}
        <DownloadJsonMenuItem
          data={{
            captureDate: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            csq: props.shipmentData.shipment,
            carrierInvoices: props.shipmentData.billingTab.carrierInvoices,
            carrierCreditNotes:
              props.shipmentData.billingTab.carrierCreditNotes,
            additionalCharges: props.shipmentData.billingTab.additionalCharges,
            customerInvoices: props.shipmentData.billingTab.customerInvoices,
          }}
          fileName="invoice-audit-data.json"
          name="Download Invoice Audit JSON Data"
        />
      </Menu.SubMenu>

      {shipment.state === ShipmentState.QuoteRequested && (
        <>
          <Menu.Divider />
          <MarkQuotedMenuItem {...props} />
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

function PageTitle(props: TabProps) {
  const shipment = props.shipmentData.shipment.shipment;
  const quote = props.shipmentData.shipment.quote;
  const pickupAddress = shipment.pickupLocation?.address;
  const deliveryAddress = shipment.deliveryLocation?.address;

  const fromCity = `${pickupAddress?.city}, ${pickupAddress?.stateOrProvinceCode}`;
  const toCity = `${deliveryAddress?.city}, ${deliveryAddress?.stateOrProvinceCode}`;
  const fromBusiness = shipment.pickupLocation?.businessName;
  const toBusiness = shipment.deliveryLocation?.businessName;
  const isStarred = isShipmentStarred(shipment.shipmentId!);
  return (
    <HorizontalStack>
      <div>
        {isStarred ? "⭐️" : "📦"}{" "}
        {props.shipmentData.shipment.company.companyName}
      </div>
      <Spacer width={32} />
      {shipment.bookedAt !== undefined && (
        <Stack align="center">
          <CarrierLogo
            carrierIdentifier={quote.carrierIdentifier || ""}
            brokeredCarrierIdentifier={quote.brokeredCarrierIdentifier}
            width={60}
            height={30}
          />
          <div
            style={{
              fontWeight: "300",
              fontSize: "8px",
              marginTop: "8px",
            }}
          >
            {quote.serviceIdentifier}
          </div>
        </Stack>
      )}
      <Spacer width={8} />
      <Stack align="left">
        <div style={{ fontSize: "16px" }}>{fromBusiness}</div>
        <div style={{ color: Colors.LightText, fontSize: "14px" }}>
          {fromCity}
        </div>
      </Stack>
      <Spacer width={8} />
      <ArrowRightOutlined width={12} />
      <Spacer width={8} />
      <Stack align="left">
        <div style={{ fontSize: "16px" }}>{toBusiness}</div>
        <div style={{ color: Colors.LightText, fontSize: "14px" }}>
          {toCity}
        </div>
      </Stack>
    </HorizontalStack>
  );
}

interface ViewShipmentHeaderProps extends TabProps {
  children: ReactNode;
  widget: ReactNode;
}

export function ViewShipmentHeader(props: ViewShipmentHeaderProps) {
  const shipmentData = props.shipmentData;
  const { shipment } = props.shipmentData.shipment;
  const { attach } = useAttach();

  const { companyId, proNumber } = shipment;
  const state = shipment.state!;

  const bookedAtDate = dayjs(shipment.bookedAt);

  if (state === undefined) {
    throw new Error("Should not be here");
  }

  function onAttach() {
    if (companyId === undefined) {
      throw new Error("Should not be here");
    }

    attach(companyId);
  }

  const cheapestQuote = props.shipmentData.manageQuotesTab.cheapestQuote;

  const isInPredictedDeliveryWindow = isInDeliveryWindow(
    shipmentData.shipment.shipment,
  );
  const deliveryColor = isInPredictedDeliveryWindow ? "automatic" : "red";

  const tags: ReactNode[] = [];

  shipmentData.shipment.shipment.tags?.forEach((s) =>
    tags.push(<Tag color="orange">{s}</Tag>),
  );
  shipmentData.shipment.shipment.problems?.forEach((s) =>
    tags.push(<ShipmentProblemTag code={s} />),
  );

  if (shipment.freightMissing) {
    tags.push(
      <Tag color="yellow">
        <WarningComponent /> Freight is Missing
      </Tag>,
    );
  }

  if (shipment.hotFreight) {
    tags.push(<Tag color="red">🔥 Freight is Hot</Tag>);
  }

  if (shipment.manualQuotingOpen) {
    tags.push(<Tag color="purple">🦸‍♀️ Manual Quoting Open</Tag>);
  }

  if (shipment.deleted) {
    tags.push(<Tag color="orange">‼️ Deleted : {shipment.deletionReason}</Tag>);
  }

  if (shipment.apolloQuotingFlagged) {
    tags.push(<Tag color="red">🚩 Quoting Flagged</Tag>);
  }

  function showExpectedDeliveryDate() {
    switch (state) {
      case ShipmentState.QuoteRequested:
        return false;
      case ShipmentState.Quoted:
        return false;
      case ShipmentState.BookingRequested:
        return true;
      case ShipmentState.BookingConfirmed:
        return true;
      case ShipmentState.BookingFailed:
        return true;
      case ShipmentState.InTransit:
        return true;
      case ShipmentState.Delivered:
        return false;
      case ShipmentState.Cancelled:
        return false;
      case ShipmentState.Lost:
        return false;
      case ShipmentState.OnHold:
        return false;
      default:
        assertNever(state);
    }
  }

  function showActualDeliveryDate() {
    return state === ShipmentState.Delivered;
  }

  const margin = props.shipmentData.shipmentReport
    ? calculateMargin(
        props.shipmentData.shipmentReport.moneyInCad!,
        props.shipmentData.shipmentReport.moneyOutCad!,
      )
    : undefined;

  const mapUrl = getMapUrlForShipment(shipment);

  return (
    <>
      <Page
        // onBack={() => window.history.back()}
        title={<PageTitle {...props} />}
        tags={tags}
        extra={[
          <ButtonRow
            key="button-row"
            style={{ marginTop: isPhone ? "4px" : "none" }}
          >
            <ViewCompanyButton
              companyId={props.shipmentData.shipment.company.companyId!}
              openInNewTab
              type="default"
            />
            <ViewEmailConversationsButton {...props} />
            <ClaudeButton {...props} />
            <Button href={mapUrl} target="_blank">
              🗺 Map
            </Button>
            <Tooltip
              placement="topLeft"
              title="Attach your user to this company"
            >
              <Button onClick={onAttach} key="attach">
                Attach
              </Button>
            </Tooltip>
            <ExtrasMenu {...props} />
          </ButtonRow>,
        ]}
        widget={props.widget}
        stats={
          <HorizontalStack verticalAlign="top">
            <ShipmentStat
              title="State"
              value={`${emojiForShipmentState(state)} ${describeState(state)}`}
            />
            <Spacer width={32} />
            {state === ShipmentState.BookingRequested && (
              <>
                <ShipmentStat
                  title="Booking started"
                  value={
                    <HorizontalStack align="left">
                      <Typography.Text strong>
                        {bookedAtDate.fromNow()}
                      </Typography.Text>
                      ,
                      <Spacer width={3} />
                      <Typography.Text>
                        {bookedAtDate.format("MMMM Do YYYY")}
                      </Typography.Text>
                    </HorizontalStack>
                  }
                />
                <Spacer width={32} />
              </>
            )}
            {shipment.bookedAt !== undefined && (
              <>
                <ShipmentStat
                  title={
                    props.shipmentData._configuration.mainTrackingNumberName
                  }
                  value={proNumber}
                  copyToClipboard
                  renderEmptyAsUnknown
                />
                <Spacer width={32} />
              </>
            )}

            {shipment.bookedAt === undefined && (
              <ShipmentStat
                title="Best Customer Price"
                value={
                  cheapestQuote ? (
                    <NumericFormat
                      value={cheapestQuote.priceCad}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"CAD$"}
                    />
                  ) : (
                    <>No quotes</>
                  )
                }
              />
            )}
            <Spacer width={32} />
            <ShipmentStat
              title="Description"
              value={shipment.lineItems![0].description}
            />
            <Spacer width={32} />
            <ShipmentStat title="Units" value={shipment.totalHandlingUnits} />
            <Spacer width={32} />
            <ShipmentStat
              title="Total Weight"
              value={
                <NumericFormat
                  value={shipment.totalWeight}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={0}
                  suffix={"lb"}
                />
              }
            />
            {shipment.state !== ShipmentState.Quoted &&
              shipment.originalPickupDate !== shipment.pickupDate && (
                <>
                  <Spacer width={32} />
                  <ShipmentStat
                    title={
                      <>
                        Original Pickup Date <WarningComponent />
                      </>
                    }
                    value={formatDate(shipment.originalPickupDate)}
                  />
                </>
              )}

            {shipment.pickupDeadline && (
              <>
                <Spacer width={32} />
                <ShipmentStat
                  title="📆 Pickup Deadline"
                  value={formatDate(shipment.pickupDeadline)}
                />
              </>
            )}

            <Spacer width={32} />
            <ShipmentStat
              title="Pickup Date"
              value={formatDate(shipment.pickupDate)}
            />

            {shipment.deliveryDeadline && (
              <>
                <Spacer width={32} />
                <ShipmentStat
                  title="📆 Delivery Deadline"
                  value={formatDate(shipment.deliveryDeadline)}
                />
              </>
            )}

            {showExpectedDeliveryDate() && (
              <>
                <Spacer width={32} />

                <ShipmentStat
                  title={
                    <>
                      Expected Delivery Date{" "}
                      <InfoBubble
                        overlay={
                          <ExpectedDeliveryDateOverlay
                            data={props.shipmentData}
                          />
                        }
                      />
                    </>
                  }
                  value={
                    <div style={{ color: deliveryColor }}>
                      {formatDate(shipment.expectedDeliveryDate)}
                    </div>
                  }
                />
              </>
            )}

            {showActualDeliveryDate() && (
              <>
                <Spacer width={32} />

                <ShipmentStat
                  title={
                    <>
                      Delivery Date{" "}
                      <InfoBubble
                        overlay={
                          <ExpectedDeliveryDateOverlay
                            data={props.shipmentData}
                          />
                        }
                      />
                    </>
                  }
                  value={
                    <div style={{ color: deliveryColor }}>
                      {formatDate(shipment.actualDeliveryDate)}
                    </div>
                  }
                />
              </>
            )}
            <Spacer width={32} />
            {margin !== undefined && (
              <ShipmentStat
                title="Margin"
                value={<Percentage colored>{margin}</Percentage>}
              />
            )}
          </HorizontalStack>
        }
      >
        {props.children}
      </Page>
    </>
  );
}
