import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { ViewJsonMenuItem } from "../../Components/ViewJsonMenuItem";
import { TabProps } from "./TabProps";

export function ViewShipmentDataMenuItem(props: TabProps & MenuItemProps) {
  return (
    <ViewJsonMenuItem
      {...props}
      data={props.shipmentData}
      title="View Shipment Data"
    />
  );
}
